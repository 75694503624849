@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu/Ubuntu-Regular.ttf");
}
@font-face {
  font-family: "Worksans";
  src: url("../fonts/Worksans/WorkSans-Regular.ttf");
}

:root {
  font-family: Ubuntu;
  --ion-font-family: Ubuntu;

  h1,
  h2,
  h3,
  h4,
  h5,
  ion-title,
  .title-font {
    font-family: Worksans;
    --ion-font-family: Worksans;
  }
}
