tcc-mono-dialog-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  .dialog-layout-wrapper {
    height: var(--height);
    display: flex;
    flex-direction: column;

    .dialog-layout-header {
      flex: 0 0 auto;
    }

    .dialog-layout-content {
      padding: var(--ion-padding);
      flex: 1 0 0;
      overflow-y: auto;
    }

    .dialog-layout-actions {
      padding: var(--ion-padding);
      flex: 0 0 auto;
    }
  }
}

@media only screen and (min-width: 768px) {
  ion-modal {
    &.modal-min-height {
      --height: 70vh;
      --min-width: 600px;
    }

    &.modal-almost-full {
      --height: 95vh;
      --width: 95vw;
    }

    &.stacked-modal {
      --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
      --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  ion-modal {
    --height: 100vh;
  }
}
